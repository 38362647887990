$primary-color:#2d353c;// #23282d;
$light-grey: #F7F7F7;
$white-color: #ffffff;
$activebg-color: #0073aa;
$hoverbg-color: #191e23;
$hovertext-color: #00b9eb;
$red: #ef8e16c7;
$green: #008000;
$gray: #111;
$gray4:#D9DEE4;
$border-color: #ced4da;
$black2d:#2d353c;
