// import base
@import './base'; 

*::-webkit-scrollbar {
    width: 3px;
}
*::-webkit-scrollbar-thumb {
    background-color: $activebg-color;
}
*::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.btn-primary {
    background-color: $black2d;
    border-color: $black2d;
    &:hover{
        background-color: $activebg-color;
        border-color: $activebg-color;
    }
    &:active{
        background-color: $activebg-color !important; 
        border-color: $activebg-color !important;
    }
    &:focus{
        background-color: $activebg-color;
        border-color: $activebg-color;
        box-shadow: none;
    }
}

.label-pending{
    background-color: #f39c12;
}
.label-cancel{
    background-color: #dd4b39;
}
.label-shipped{
    background-color: #3c8dbc;
}
.label-success{
    background-color: #00a65a;
}

.badge {
    color: $white-color;
    margin: 0px 3px;
    padding: 5px 5px;
    font-size: 100%;
    &:hover{
        color: $white-color;
    }
}
.bg-light-blue{
    background-color: $activebg-color;
}
.bg-red{
    background-color: $red;
}

.label-success,.label-shipped,.label-cancel,.label-pending{
    display: inline;
    padding: .2em .6em .3em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: .25em;
}

body{
    font-size: 13px;
    letter-spacing: 0.25px;
} 

.width-100{
    width: 100%;
}

.custom-filtercls .custom-filter-btn {
    margin-top: -58px;
}
.custom-filtercls .custom-filtercls-pos .card-body {
    box-shadow: none;
}

.project-name{
    font-size: 20px;
    padding: 18px 10px; 
}
.logoadmin {
    background-color: #fff;
    text-align: left;
    padding: 7px 15px;
    border-bottom: 2px solid #0073aa;
}
.open-sidebar{
    .user-panel{
        .info{
            display: none;
        }
    } 
}
.logoadmin .logo-img {
    max-width: 198px;
}
.user-panel {
    position: relative;
    width: 100%;
    padding: 10px;
    overflow: hidden;
    .img-circle {
        width: 100%;
        max-width: 45px;
        height: auto;
        border-radius: 50%;
    }
    .info{
        padding: 5px 5px 5px 15px;
        line-height: 1;
        p {
            font-weight: 600;
            margin-bottom: 9px;
        }
        a{
            text-decoration: none;
            padding-right: 5px;
            margin-top: 3px;
            font-size: 11px;
            color: #fff;
        }
    }
}
.open-sidebar{
    .logoadmin{
        padding: 21px 5px;
        .logo-img{
            max-width: 42px;
        }
    }
}
.auth-wrapper {
    background-color: #d3d3d3;
    height: 100vh;
    padding-left: 15px;
    padding-right: 15px;

    .auth-inner-wrapper {
        width: 100%;
        max-width: 400px;
        background-color: #ffff;
        color: #ffffff;
        border-radius: 10px;
        &.loginpage,&.frogotpassword{
            .auth-label{
                margin-bottom: 8px;
            }
            .auth-header {
                margin-bottom: 20px;
            }
            .form-group{
                .btn-primary{
                    background: #4074bb;
                    border-color: #7b9ad0;
                    &:hover{
                        background: #007bff;
                        border-color: #007bff;
                    }
                }
            }
        }

        .auth-card {
            padding: 40px 20px;

            .auth-header {
                .auth-logo {
                    text-align: center;
                    margin: 0 auto;
                    img{
                        max-width: 90%;
                        max-height: 94px;
                    }
                }
            }

            .auth-body {

                .auth-form-field {
                    .auth-label {
                        color: black;
                        font-size: 14px;
                    }

                    .form-control {
                        // border-radius: 0 !important
                    }
                }

                .auth-btn-wrapper {
                    .submit-btn {}
                }
            }

            .auth-footer {}
        }
    }
}

// start---------------------------------------------------------------------------------portal after login---------------------------------------
.main-container {
    width: 100%;
    
    header {
        position: fixed;
        z-index: 1;
        width: calc(100% - 220px);
         right: 0;
         -webkit-transition: -webkit-transform .3s ease-in-out, width .3s ease-in-out;
         -moz-transition: -moz-transform .3s ease-in-out, width .3s ease-in-out;
         -o-transition: -o-transform .3s ease-in-out, width .3s ease-in-out;
         transition: transform .3s ease-in-out, width .3s ease-in-out;
        .dropdown-toggle{
            &:after{
                display: none;
            }
        }
        .label {
            position: absolute;
            top: 9px;
            right: 7px;
            text-align: center;
            font-size: 10px;
            padding: 4px 5px;
            line-height: .9;
            color: $white-color;
            border-radius: 50%;
        }
        .label-warning{
            background-color: $red;
        }
        
        .header-wrapper {
            height: auto;
            padding: 0px 0px;
            background: $activebg-color;
            border-bottom: 1px solid $gray4;
            float: left;
            width: 100%;
 
            .header-inner {
                .project-name {
                    padding: 10px 10px;
                    font-size: 20px;
                }
                .close-open {
                    float: left;
                    button{
                        color: $white-color;
                        text-transform: capitalize;
                        padding: 16px 20px;
                        border: none;
                        font-size: 20px;
                        background: transparent;
                        &:focus{
                            outline: none;
                        }
                    }
                }
                .all-control {
                    float: right;
                    ul {
                        li {
                            padding-left: 10px;
                        }
                    }

                }
            }
        }
    }

    main {
        aside {
            padding-top: 0px;
            width: 220px;
            background-color: $primary-color;
            color: $white-color;
            position: fixed;
            height: 100%;
            -webkit-transition: -webkit-transform .3s ease-in-out, width .3s ease-in-out;
    -moz-transition: -moz-transform .3s ease-in-out, width .3s ease-in-out;
    -o-transition: -o-transform .3s ease-in-out, width .3s ease-in-out;
    transition: transform .3s ease-in-out, width .3s ease-in-out;
            .sidebar-wrapper {
                ul{
                    float: left;
                    width: 100%;
                    li{
                        float: left;
                        width: 100%;
                        margin-bottom: 0px;
                        a{
                            color: $white-color;
                            padding: 8px 20px 8px 0px;
                            font-size: 14px;
                            width:100%;
                            float:left;
                            text-decoration: none;
                            line-height: 34px;
                            position: relative;
                            &.active{
                                background-color: $activebg-color;
                                color: $white-color;
                            }
                            &:hover{
                               background-color: $hoverbg-color;
                               color: $hovertext-color;
                            }
                        }
                    }
                }
            }
        }

        .content-wrapper {
            width: calc(100% - 220px);
            background-color: $light-grey;
            min-height: calc(100vh - 70px);
            -webkit-transition: -webkit-transform .3s ease-in-out, width .3s ease-in-out;
            -moz-transition: -moz-transform .3s ease-in-out, width .3s ease-in-out;
            -o-transition: -o-transform .3s ease-in-out, width .3s ease-in-out;
            transition: transform .3s ease-in-out, width .3s ease-in-out;
            .content {
                padding: 20px;
                margin-top: 60px;
                .album{
                    .container-fluid{
                        .table-responsive{
                            .table{
                                .ima-size-30{
                                    width: 100px;
                                    
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

// ends---------------------------------------------------------------------------------portal after login---------------------------------------

// starts -------------------------------------------------pages---------------------------------------------------------------------------------

// start---------dashboard-------------
.dashboard,.album{
    background-color: $white-color
}

// ends---------dashboard-------------

//  -------------------------------------------------pages---------------------------------------------------------------------------------

.all-control{
    .navbar-light{
        padding: 0;
        .navbar-nav{            
           .nav-link{
                color: $white-color;
                text-transform: capitalize;
                padding: 20px;
                font-size: 15px;
                &:focus,&:hover{
                   color: $gray;
                   background: $gray4;
                }

           }
           .show{
              .nav-link{
                color: $gray;
                background: $gray4;               
              }
              .dropdown-menu{
                    left: initial;
                    right: 0;
                    min-width: 14rem;
                    margin: 0px 0 0;
                    border-radius: 0;                    
                    background: $white-color;
                    box-shadow: 0px 0px 3px;
                    border: none;
                    .dropdown-item{
                        color: $gray;
                        padding: 10px 25px;
                        border-bottom: 1px solid $border-color;
                        &:last-child{
                            border-bottom: none;
                        }
                        &:hover,&:focus{
                            color: $hovertext-color;                           
                            background-color: transparent;
                        }
                        .img-size-40{
                            width: 40px;
                            height: 40px;
                            border-radius: 26px
                        }
                        .margin-left-bottom{
                            margin-right: 16px;
                            margin-bottom: 10px;
                        }
                    }
                }
            }
        } 
    } 
}


.form-group{
    margin-bottom: 20px;
    .form-control{
        border: 1px solid #ddd;
        box-shadow: inset 0 1px 2px rgba(0,0,0,.07);
        background-color: #fff;
        color: #32373c;
        outline: 0;
        transition: 50ms border-color ease-in-out;
        border-radius: 0;
    }
}

.bg-light{
    // margin-bottom: 30px;
    h2 {
        padding: 6px 15px;
        font-size: 28px;
    }
} 
table {
    thead{
        background-color: $light-grey;
        color: $gray;
    }
}
.theme-button{
    background-color: $black2d;
    z-index: 0;
}
 


.img-placeholder{
    width: 180px;
    height: 200px;
    border: 2px solid #b9a2a2;
    display: inline-block;
}
.wh-174-194{
    width: 174px;
    height: 194px;
}

// pagination class



.pagination a {
  color: $gray;
  float: left;
  padding: 8px 16px;
  text-decoration: none;
  transition: background-color .3s;
  border: 1px solid $border-color;
}

.pagination li.active a {
  background-color: $black2d;
  color: white;
  border: 1px solid $black2d;
}

.pagination a:hover:not(.active) {background-color: $border-color;}

  // pagination class close


// loader class start

.loader-custom {    
    //@at-rootz-index: 999;
    margin: 0 auto;
    width: 100%;
    display: block;
    text-align: center;
    height: 100%;
    
}
.loader-custom > div{
    background: rgba(0,0,0,0.4);
    height: 100%;
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
}
.loader-custom > div svg {
    top: 50%;
    transform: translateY(50%);
    position: absolute;
}
.postion-relative{
    position: relative;
}

// loader class close

.disabledCursor { 
    cursor: default; // for disabled link cursor
}

.d-flex-grid{
    display: grid;
}
.d-flex{
    position: relative;
}


.tooltipcls{
    .Pending,.Verified,.Unverified{
        color: #fff;
        background-color: transparent;
        border-color: transparent;
        border: none;
        padding: 2px 10px;
        font-size: 14px;
        border-radius: 0;
    }
    .Pending{
        background-color: #5bc0de;
    }
    .Verified{
        background-color: #5cb85c;
    }
    .Unverified{
        background-color: #777;
    }
}
.tooltipcls .not-verify,.tooltipcls .solded,.tooltipcls .used {
   padding: 0px 0px;
   text-align: center;
   color: #fff;
   font-size: 13px;
   font-weight: 700;
   box-shadow: 0px 0px 3px #000;
   margin-bottom: 10px;
}
.tooltipcls .used {
    background: #717179;
}
.tooltipcls .solded {
    background: #615b5b;
}
.tooltipcls .not-verify {
    background: #905a5a;
}
.tooltipcls .verified{
    background: #76905a;
}
//media query



/*
=======================
Start Filter
=======================
*/ 
.custom-filtercls{
    width: 100%;
    text-align: right;
    .custom-filter{
        margin-left: 20px;
      &:focus{
        box-shadow: none !important;
      }
    }
    .card-body{
        border: 1px solid rgba(0,0,0,.1);
        box-shadow: 0px 0px 5px;
    }
    .form-group{
        text-align: left;
        .form-field{
            margin-bottom: 15px;
        }
        select{
            display: block;
            width: 100%;
            height: calc(1.5em + .75rem + 2px);
            padding: .375rem .75rem;
            font-size: 1rem;
            font-weight: 400;
            line-height: 1.5;
            color: $gray;
            background-color: $white-color;
            background-clip: padding-box;
            border: 1px solid $border-color;
            border-radius: .25rem;
            transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
        }
        .form-field{
            label{
                font-weight: 600;
            }
            input{
                display: block;
                width: 100%;
                height: calc(1.5em + .75rem + 2px);
                padding: .375rem .75rem;
                font-size: 1rem;
                font-weight: 400;
                line-height: 1.5;
                color: $gray;
                background-color: $white-color;
                background-clip: padding-box;
                border: 1px solid $border-color;
                border-radius: .25rem;
                transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
                
            }
        }
    }
}

.input-group{
    z-index: 0;

}

/*
=======================
End Filter
=======================
*/ 

/*
=======================
Start Dashboard
=======================
*/ 

.dashboard{
    .ads-analytics{
        padding-bottom: 50px;
        .list-item-detail {
            border-radius: 2px;
            position: relative;
            display: block;
            margin-bottom: 20px;
            box-shadow: 0 1px 1px rgba(0,0,0,0.1);
            h3 {
                font-size: 38px;
                font-weight: bold;
                margin: 0 0 10px 0;
                white-space: nowrap;
                padding: 0;
            }
            .inner{
                padding: 10px;
                color: #fff;
            }
            .icon {
                transition: all .3s linear;
                position: absolute;
                top: 50%;
                right: 10px;
                z-index: 0;
                font-size: 74px;
                color: rgba(0, 0, 0, 0.15);
                transform: translateY(-50%);
                
            }
            &:hover{
                .icon{
                    font-size: 80px;
                }
            }
           
            span{
                font-size: 15px;
            }
            .small-box-footer {
                position: relative;
                text-align: center;
                padding: 3px 0;
                color: $white-color;
                color: rgba(255,255,255,0.8);
                display: block;
                background: rgba(0,0,0,0.1);
                text-decoration: none;
                .fa {
                    vertical-align: middle;
                    margin-left: 5px;
                }
            }
        }
    }
}

.list-item-detail{
    &.bg-aqua{
        background-color: #00c0ef;
    }
    &.bg-light-blue{
        background-color: #3c8dbc;
    }
    &.bg-teal{
        background-color: #39cccc;
    }
    &.bg-red{
        background-color: #dd4b39;
    }
    &.bg-yellow{
        background-color: #f39c12;
    }
    &.bg-green{
        background-color: #00a65a;
    }
}

.dashboard{
    ul{
        list-style: none;
        margin: 0;
        padding: 0;
    }
}

.dashboard-item{
    margin-bottom: 3rem;
    .dashboard-item-title {
        font-size: 1.4rem;
        font-weight: 700;
    }
    .dashboard-sales-value {
        font-size: 1.5rem;
        font-weight: 600;
    }
    &.dashboard-item-primary{
        &:first-child{
            .dashboard-sales-value {
                color: $activebg-color;
            }
        }
    }
}
.dashboard-item-content{
    .table-responsive{
        table{
            thead{
                background-color: transparent;
                color: $gray;
                th{
                    border-bottom: none;
                    border-top: none;
                    padding: 20px 0;
                    &:nth-child(1){
                        text-align: left;
                    }
                    &:nth-child(2){
                        text-align: center;
                    }
                    &:nth-child(3){
                        text-align: right;
                    }
                }
            }
            tbody{
                td{
                    padding: 10px 0;
                    &:nth-child(1){
                        text-align: left;
                    }
                    &:nth-child(2){
                        text-align: center;
                    }
                    &:nth-child(3){
                        text-align: right;
                    }
                }
            }
        } 
    }
}

.customer-report-main{
    .nav-tabs{
        .nav-link{
            font-size: 15px;
            letter-spacing: 0.5px;
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            color: $white-color;
            background-color: $activebg-color;
            border-color: $border-color;
            &.active{
                color: $gray;
                background-color: $white-color;
                border-bottom: .1rem solid #fff;
            }
        }
    }
    .tab-content{
        .customer-report{
            table{
                thead{
                    background-color: transparent;
                    color: $gray;
                    th{
                        border-bottom: none;
                        border-top: none;

                    }
                }
            } 
        }
        .tab-pane{
            &:nth-child(1){
               th{
                &:nth-child(1){
                    text-align: left;
                }
                &:nth-child(2){
                    text-align: center;
                }
                &:nth-child(3){
                    text-align: center;
                }
                &:nth-child(4){
                    text-align: center;
                }
               }
               td{
                &:nth-child(1){
                    text-align: left;
                }
                &:nth-child(2){
                    text-align: center;
                }
                &:nth-child(3){
                    text-align: center;
                }
                &:nth-child(4){
                    text-align: center;
                }
               }
            }
            &:nth-child(2){
                th{
                    &:nth-child(1){
                        text-align: left;
                    }
                    &:nth-child(2){
                        text-align: center;
                    }
                    &:nth-child(3){
                        text-align: right;
                    }
                }
                td{
                    &:nth-child(1){
                        text-align: left;
                    }
                    &:nth-child(2){
                        text-align: center;
                    }
                    &:nth-child(3){
                        text-align: right;
                    }
                }
            }
            &:nth-child(3){
                th{
                    &:nth-child(1){
                        text-align: left;
                    }
                    &:nth-child(2){
                        text-align: center;
                    }
                    &:nth-child(3){
                        text-align: center;
                    }
                    &:nth-child(4){
                        text-align: right;
                    }
                }
                td{
                    &:nth-child(1){
                        text-align: left;
                    }
                    &:nth-child(2){
                        text-align: center;
                    }
                    &:nth-child(3){
                        text-align: center;
                    }
                    &:nth-child(4){
                        text-align: right;
                    }
                }
            }
            &:nth-child(4){
                th{
                    &:nth-child(1){
                        text-align: left;
                    }
                    &:nth-child(2){
                        text-align: center;
                    }
                    &:nth-child(3){
                        text-align: center;
                    }
                    &:nth-child(4){
                        text-align: right;
                    }
                }
                td{
                    &:nth-child(1){
                        text-align: left;
                    }
                    &:nth-child(2){
                        text-align: center;
                    }
                    &:nth-child(3){
                        text-align: center;
                    }
                    &:nth-child(4){
                        text-align: right;
                    }
                }
            }
        }
    }
}

.table-responsive{
    table{
        thead{
            background-color: $light-grey;
        }
    } 
}

.form-group{
    .form-control{
        box-shadow: none;
    }
} 
.upload-img{
    .form-group{
        .form-control {
            padding: 10px 15px;
            height: auto;
        }
    } 
}   

/*
=======================
End Dashboard
=======================
*/ 





/*
=======================
Start Siderbar
=======================
*/ 

.icons-cls {
    float: left;
    width: 36px;
    height: 34px;
    margin: 0;
    text-align: center;
    i{
        padding: 8px 0;
        transition: all .1s ease-in-out;
        display: inline-block;
        line-height: 1;
        font-weight: 400;
        font-style: normal;
        speak: none;
        text-decoration: inherit;
        text-transform: none;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 18px;
        vertical-align: top;
        text-align: center;
    }
}

.sub-categorys {
    padding: 0;
    list-style: none;
    position: relative;
    &.hide{
        overflow: hidden;
        max-height: 0;
        padding-top: 0;
        padding-bottom: 0;
        margin-top: 0;
        margin-bottom: 0;
        transition: all .9s ease-in-out;
        
    }
    &.show{
        max-height: 1000px;
        overflow: hidden;
        transition: all .9s ease-in-out;
        will-change: transform;
    } 
    li{
        margin-bottom: 0px;
        a {
            display: block;
            padding: 0px 0px 0px 15px !important;
            font-size: 13px !important;
        }
    } 
    .icons-cls {
        width: auto;
        height: auto;
        float: none;
        display: inline-block;
        vertical-align: middle;
        margin-right: 7px;
        i{
            font-size: 13px;
        }
    }
}

.subcls{
    a{
        &.arrow{
            &:after{
                content: "\f105";
                font-family: FontAwesome;
                float: right;
            }
            &.activec{
                &:after{
                    content: "\f107";
                    font-family: FontAwesome;
                    float: right;
                }
            }
        }
    }
}




.sidebar-main{
    .sliding-panel-container{
        &.active{
            position: relative;
        }
    }
}

 

// Hover

@media (min-width: 768px){

    .open-sidebar{
        .project-name {
            font-size: 12px;
            padding: 23px 0px;
        }
        .main-container{
            main{
                aside{
                    width: 50px;
                    -webkit-transform: translate(0, 0);
                    -ms-transform: translate(0, 0);
                    -o-transform: translate(0, 0);
                    transform: translate(0, 0);
                    z-index: 1;
                }
                header{
                    width: calc(100% - 50px);
                }
                .content-wrapper{
                    width: calc(100% - 50px);
                }
            } 
        }
        .main-container{
            .sidebar-wrapper{
                ul{
                    li{
                        a{
                            padding: 0;
                            span{
                                display: none;
                            }
                        }
                    } 
                } 
            }  
        } 
        .sidebar-main{
            .sliding-panel-container{
                .panel{
                    width: 100% !important;
                    overflow: visible !important;
                }
            } 
        }
        .icons-cls{
            float: none;
            margin: 0 auto;
        }
        .subcls{
            position: relative;
            a.arrow:after{
                display: none;
            } 
        }   
    }    

.open-sidebar{
    .main-container{
        .sidebar-wrapper{
            ul{
                li{
                   position: relative;
                   .sub-categorys{
                    transition: none !important;
                       li{
                        margin-bottom: 0;
                       }
                   }
                   &:hover{
                        a{  
                            span{
                                display: block !important;
                                padding-left: 15px;
                                position: absolute;
                                width: 180px;
                                left: 50px;
                                top: 0;
                                margin-left: 0;
                                background-color: $hoverbg-color;
                            }
                        }
                        .sub-categorys.hide {
                            max-height: 1000px;
                            overflow: hidden;
                            position: absolute;
                            width: 180px;
                            left: 50px;
                            top: 34px;
                            background-color: $hoverbg-color;
                            color: $white-color;
                            transition: all .9s ease-in-out !important;
                        }
                        .sub-categorys{
                            .icons-cls{
                                padding-right: 10px;
                            }
                        }  
                    }
                    
                }
            }
        }
    }
}

}

/*
=======================
End Siderbar
=======================
*/ 


.website-themes{
    h4{
        font-size: 16px;
    }
    .thumbnail {
        display: block;
        padding: 4px;
        margin-bottom: 20px;
        line-height: 1.42857143;
        background-color: #fff;
        border: 1px solid #ddd;
        border-radius: 4px;
        -webkit-transition: border .2s ease-in-out;
        -o-transition: border .2s ease-in-out;
        transition: border .2s ease-in-out;
    }
}


/*
===========================
Responsive Css
===========================
*/ 


@media (max-width: 767px){

    .bg-light h2{
        font-size: 18px;
    }

   //Sidebar
    .main-container{
        header{
            width: calc(100% - 0px);
        }
        main{
            aside{
                margin-top: 63px;
                display: none;
            }
            .content-wrapper{
                width: calc(100% - 0px);
            }
        } 
    }
    
    .open-sidebar{
        .main-container{
            main{
                header{
                    width: calc(100% - 0px);
                }
                aside{
                    width: 220px;
                    display: block;
                }
                .content-wrapper{
                    width: calc(100% - 220px);
                }
            } 
        } 
    } 

}

.content {
    //position: relative;
}

.btn-search{
    position: relative;
    top: 11%;
}
.select-seminar{
    position: relative;
    top: -16%;
}
.forget{
    color: black;
    font-weight: bold;
    font-size: 20px;
    margin-top: 15px;
}
a.remove-image {
    position: absolute;
    /* right: 0; */
    margin: -11px;
    background: bisque;
    border-radius: 50%;
    padding: 4px 10px;
}

